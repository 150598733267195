(function ($, Drupal) {
  Drupal.behaviors.contentBlockButtonV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-button-trigger', context);

      $modules.each(function () {
        var $contentOverlay = $(this).closest('.js-content-block-button--v1').find('.js-content-block-overlay--v1');

        $(this).off('click.overlay').on('click.overlay', function () {
          if ($contentOverlay.length) {
            $contentOverlay.trigger('launch.overlay');
          }
        });
      });
    }
  };
})(jQuery, Drupal);
